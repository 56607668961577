<script>
import Button from "@/components/Button.vue";
import TabsInfos from "@/components/TabsInfos.vue";
import ProjectCard from "@/components/ProjectCard.vue";
import HoverExperiences from "@/components/HoverExperiences.vue";
import Footer from "@/components/Footer.vue";
import ModalError from "@/components/ModalError.vue";
import ModalContact from "@/components/ModalContact.vue";

export default {
    data() {
        return {
            workingExperiencesList: {
                "FlamePay": {
                    positionAtWork: "Desenvolvedor Full Stack",
                    startedIn: "02/02/2024",
                    finishedIn: false,
                    description: "Atuei diretamente com o gerenciamento de equipes, controle de tarefas e prazos para entregas de tarefas, conversas com clientes, DevOps e serviços de nuvem, Líder Técnico em Laravel e Vue.js e analista de sistemas. Responsável por gerenciar a equipe de desenvolvimento,garantindo agilidade, segurança e qualidade nas entregas.Também fui responsável por entregas de maior prioridade, que necessitavam um nível de conhecimento técnico maior para a resolução do problema.",
                    skills: [
                        "HTML5",
                        "CSS3",
                        "TailwindCSS",
                        "Vue.js",
                        "Typescript",
                        "PHP",
                        "Laravel",
                        "C#",
                        ".NET Core",
                        "Entity Framework",
                        "LINQ",
                        "AWS",
                        "UI/UX",
                        "Figma",
                        "Metodologias Agile",
                        "Jira",
                    ]
                },
                "Pepper": {
                    positionAtWork: "Desenvolvedor Full Stack",
                    startedIn: "01/09/2022",
                    finishedIn: "01/02/2024",
                    description: "Responsável pelo desenvolvimento de novas plataformas e tecnologias para o grupo Pepper e pela manunteção dos sistemas já existentes. Fui responsável por uma aplicação para o grupo que conta com mais de 2 milhões de usuários cadastrados e mais de 300 mil usuários ativos/mês.",
                    skills: [
                        "HTML5",
                        "CSS3",
                        "TailwindCSS",
                        "Vue.js",
                        "C#",
                        ".NET Core",
                        "Entity Framework",
                        "LINQ",
                        "PHP",
                        "Laravel",
                        "AWS",
                        "UI/UX",
                        "Figma",
                        "Metodologias Agile",
                        "Jira",
                    ]
                },
                "Cometa Code": {
                    positionAtWork: "Desenvolvedor Full Stack",
                    startedIn: "01/01/2022",
                    finishedIn: "01/10/2023",
                    description: "Líder de projetos para empresas de pequeno e médio porte. Fui responsável por todo o processo de desenvolvimento de sistemas web, mobile e desktop de diversas modalidades e níveis de complexidade.",
                    skills: [
                        "HTML5",
                        "CSS3",
                        "TailwindCSS",
                        "Bootstrap",
                        "JQuery",
                        "Vue.js",
                        "PHP",
                        "Laravel",
                        "AWS",
                        "MySQL",
                        "UI/UX",
                    ]
                },
                "HZZ Devs": {
                    positionAtWork: "Desenvolvedor Back-end",
                    startedIn: "01/04/2021",
                    finishedIn: "01/12/2021",
                    description: "Trabalhei arquitetando e desenvolvendo projetos para uma plataforma da Rockstar Games (FiveM). Tive diversos desafios com grandes empresários do setor, trazendo soluções lucrativas para os seus negócios. Através das soluções desenvolvidas para essa plataforma, conseguimos atingir faturamentos de 8 dígitos para nossos clientes",
                    skills: [
                        "Lua",
                        "C#",
                        "vRP 1.0",
                        "Cfx.re",
                        "FiveM",
                        "Natives",
                        "PHP",
                        "Laravel",
                    ]
                },
                "Space Design": {
                    positionAtWork: "Designer Gráfico",
                    startedIn: "01/07/2020",
                    finishedIn: "01/01/2021",
                    description: "Trabalhei na formulação e criação de criativos, logos e flyers para empresas, a fim de proporcionar um Marketing mais inteligente e gerar conversão de clientes e vendas. Tive contato muito próximo com Edição de imagens, edição de vídeos, lançamentos, marketing digital e tráfego pago em redes como Meta, Google e Youtube",
                    skills: [
                        "Canva",
                        "Adobe Photoshop",
                        "Mídias Sociais",
                        "Adobe Illustrator",
                        "Marketing Digital",
                        "Design Gráfico",
                        "Meta ADS",
                        "Google ADS",
                    ]
                },
                "Verde Sião Agrop.": {
                    positionAtWork: "Vendedor",
                    startedIn: "01/10/2017",
                    finishedIn: "01/10/2020",
                    description: "Vendedor, assistente geral e designer gráfico, atuei auxiliando no sistema de gestão da empresa, prestando atendimentos, vendas e criando artes para as mídias sociais da empresa.",
                    skills: [
                        "Adobe Photoshop",
                        "Atendimento ao cliente",
                        "Banco de dados relacional",
                        "Sistemas PDV",
                        "Vendas",
                        "Varejo",
                    ]
                },
            },
            projects: [
                {
                    imageLink: "https://vitorlbarroso.tech/p_fp.png",
                    title: "Flame Pay",
                    positionAtProject: "Líder de Equipe",
                    description: "Nesse projeto, fui responsável pela contratação de novos desenvolvedores, planejamento de tarefas através de metodologias ágeis e o líder da equipe de desenvolvimento.",
                    projectLink: "https://flamepay.com.br",
                },
                {
                    imageLink: "https://vitorlbarroso.tech/p_vp.png",
                    title: "Vídeo Player",
                    positionAtProject: "Desenvolvedor e Arquiteto",
                    description: "Desenvolvi e arquitetei toda a estrutura do vídeo player. Utilizei Javascript para a criação do Player e Buckets S3, Lambda Func. e o serviço AWS MediaConvert para armazenar e converter os vídeos.",
                    projectLink: null,
                },
                {
                    imageLink: "https://vitorlbarroso.tech/p_jm.png",
                    title: "Jump Mario",
                    positionAtProject: "Desenvolvedor",
                    description: "Desenvolvi um game utilizando Javascript. Esse game é um réplica do famoso “Joguinho do Dinossauro”, porém, adaptado ao tema do Super Mário.",
                    projectLink: "https://mario-jump-vitorlbarroso.netlify.app/",
                },
                {
                    imageLink: "https://vitorlbarroso.tech/p_hb.png",
                    title: "Hit Biz",
                    positionAtProject: "UI/UX Designer",
                    description: "Apliquei técnicas de Design e Experiência do usuário para criar uma melhor experiência para os produtores da plataforma.",
                    projectLink: null,
                },
                {
                    imageLink: "https://vitorlbarroso.tech/p_ps.png",
                    title: "Pro Seller",
                    positionAtProject: "Desenvolvedor e Arquiteto",
                    description: "Desenvolvi e arquitetei um sistema voltado para a criação de Landing Pages através de um Page Builder (Estilo WordPress). Nesse projeto, utilizei o Vue.JS, .NET Core 6 e Serviços AWS.",
                    projectLink: null,
                },
                {
                    imageLink: "https://vitorlbarroso.tech/p_pp.png",
                    title: "PicPay + PHP",
                    positionAtProject: "Desenvolvedor",
                    description: "Desenvolvi uma biblioteca de adaptação da API do PicPay com o PHP, a fim de facilitar a integração da aplicação para pessoas que queiram vender através da internet.",
                    projectLink: "https://github.com/vitorlbarroso/api-php-picpay",
                },
                {
                    imageLink: "https://vitorlbarroso.tech/p_el.png",
                    title: "Essa Loja",
                    positionAtProject: "Desenvolvedor FullStack",
                    description: "Loja desenvolvida utilizando Vue.JS e Laravel. Integrei a essa plataforma, sistema de vendas completo, sistema de envio e rastreio de pacotes via Correios e Melhor Envio",
                    projectLink: null,
                },
            ],
            languagesExperiences: [
                {
                    languageName: "HTML5",
                    imageLink: "https://vitorlbarroso.tech/html5.png",
                    description: "HTML é uma linguagem de marcação usada para criar e estruturar páginas da web. Composta por elementos e tags, HTML permite a formatação de texto, incorporação de imagens, links e outros recursos multimídia. É a espinha dorsal da web, fornecendo a base para a criação e exibição de conteúdo na internet, sendo interpretada pelos navegadores para apresentar informações de maneira visualmente coerente aos usuários.",
                },
                {
                    languageName: "CSS3",
                    imageLink: "https://vitorlbarroso.tech/css.png",
                    description: "CSS é uma linguagem de estilo utilizada para controlar a apresentação visual de páginas da web, separando o conteúdo estrutural (HTML) da sua aparência. Com recursos avançados como seletores, propriedades e valores, o CSS3 permite definir cores, fontes, espaçamento, layout e outros aspectos visuais de forma flexível e eficiente. Ele possibilita a criação de designs atrativos, responsivos e acessíveis, melhorando a experiência do usuário na navegação pela web.",
                },
                {
                    languageName: "Javascript",
                    imageLink: "https://vitorlbarroso.tech/js.png",
                    description: "JavaScript é uma linguagem de programação amplamente utilizada para criar interatividade em páginas da web. Como uma linguagem de script do lado do cliente, ela permite manipular o conteúdo HTML e CSS, responder a eventos do usuário e interagir com serviços web, tornando as páginas mais dinâmicas e funcionais.",
                },
                {
                    languageName: "Vue.js",
                    imageLink: "https://vitorlbarroso.tech/vue.png",
                    description: "Vue.js é um framework progressivo de JavaScript utilizado para construir interfaces de usuário interativas e reativas. Com uma sintaxe simples e flexível, Vue.js simplifica o desenvolvimento front-end, facilitando a criação de componentes reutilizáveis, gerenciamento de estado e integração com outras bibliotecas e ferramentas.",
                },
                {
                    languageName: "Bootstrap",
                    imageLink: "https://vitorlbarroso.tech/bs.png",
                    description: "Bootstrap é um framework front-end de código aberto utilizado para desenvolver rapidamente interfaces web responsivas e estilizadas. Com uma coleção de componentes e estilos pré-definidos, Bootstrap simplifica o processo de design, garantindo consistência visual e compatibilidade com diversos dispositivos e navegadores.",
                },
                {
                    languageName: "TailwindCSS",
                    imageLink: "https://vitorlbarroso.tech/tailwindcss.png",
                    description: "Tailwind CSS é uma biblioteca de classes CSS utilitárias que permite criar designs personalizados de forma rápida e eficiente. Em vez de fornecer componentes pré-estilizados, o Tailwind CSS fornece uma abordagem mais granular, permitindo estilizar elementos diretamente no HTML com classes específicas, oferecendo maior flexibilidade e controle sobre o design.",
                },
                {
                    languageName: "Laravel",
                    imageLink: "https://vitorlbarroso.tech/laravel.png",
                    description: "Laravel é um framework de desenvolvimento web em PHP que oferece uma estrutura robusta e elegante para construir aplicativos web modernos e escaláveis. Com recursos como Eloquent ORM, Blade templating engine e Laravel Mix, Laravel simplifica tarefas comuns de desenvolvimento, promovendo uma experiência de programação agradável e produtiva.",
                },
                {
                    languageName: ".NET Core",
                    imageLink: "https://vitorlbarroso.tech/dotnet.png",
                    description: ".NET Core é uma plataforma de desenvolvimento open-source da Microsoft utilizada para criar aplicativos modernos e escaláveis para web, desktop e dispositivos móveis. Com suporte multiplataforma e uma vasta biblioteca de APIs, o .NET Core oferece desempenho e flexibilidade, permitindo aos desenvolvedores construir e implantar aplicativos em diferentes ambientes de hospedagem.",
                },
                {
                    languageName: "AWS",
                    imageLink: "https://vitorlbarroso.tech/aws.png",
                    description: "AWS é uma plataforma de serviços de computação em nuvem fornecida pela Amazon. Oferecendo uma ampla gama de serviços, como computação, armazenamento, banco de dados, machine learning e muito mais, a AWS permite as empresas escalar e gerenciar recursos de forma flexível, segura e econômica, impulsionando a inovação e o crescimento digital.",
                },
            ],
            modalError: {
                isOpened: false,
                title: "",
                description: "",
            },
            modalContact: {
                isOpened: false,
                title: "CONTATO",
                contacts: [
                    {
                        name: "WhatsApp",
                        link: "https://api.whatsapp.com/send/?phone=%2B5527999971368&text=Ol%C3%A1,%20Vitor.%20Peguei%20o%20seu%20contato%20na%20sua%20p%C3%A1gina%20pessoal.%20Est%C3%A1%20podendo%20falar?&type=phone_number&app_absent=0",
                    },
                    {
                        name: "Instagram",
                        link: "https://www.instagram.com/vitorlbarroso/",
                    },
                    {
                        name: "LinkedIn",
                        link: "https://www.linkedin.com/in/vitorlbarroso2004/",
                    },
                    {
                        name: "E-mail",
                        link: "mailto:vitorlauvresbarroso@gmail.com",
                    },
                ]
            },
        }
    },
    methods: {
        openModalContact() {
            this.modalContact.isOpened = true;
        },
        closeModalContact() {
            this.modalContact.isOpened = false;
        },
        openModalError(title, description) {
            this.modalError.title = title;
            this.modalError.description = description;
            this.modalError.isOpened = true;
        },
        closeModalError() {
            this.modalError.isOpened = false;
            this.modalError.title = "";
            this.modalError.description = "";
        },
        downloadCV() {
            const link = document.createElement('a');
            // Define o atributo 'href' do elemento 'a' com o caminho para o arquivo
            link.href = 'https://vitorlbarroso.tech/cv_vitorbarroso.pdf'; // Substitua com o caminho real do arquivo
            // Define o atributo 'download' para que o navegador faça o download ao invés de navegar para o link
            link.download = 'cv_vitorbarroso.pdf'; // Substitua com a extensão real do arquivo
            // Adiciona o elemento 'a' ao documento
            document.body.appendChild(link);
            // Simula um clique no link para iniciar o download
            link.click();
            // Remove o elemento 'a' do documento
            document.body.removeChild(link);
        },
    },
    components: { Button, TabsInfos, ProjectCard, HoverExperiences, Footer, ModalError, ModalContact },
}
</script>

<template>
    <div>
        <ModalError v-if="modalError.isOpened" @closeModal="closeModalError" :data="modalError" />
        <ModalContact v-if="modalContact.isOpened" @closeModal="closeModalContact" :data="modalContact" />

        <header>
            <router-link class="link" to="" @click="openModalContact">Contato</router-link>
            <router-link class="link" to="" @click="openModalError('EM BREVE!', 'Em breve disponibilizaremos nosso DevBlog com diversos conteúdos exclusivos sobre tecnologia. Fique ligado!')">Blog</router-link>
        </header>

        <section id="personal-presentation" class="container">
            <article id="infos-personal-presentation">
                <h1>Vitor Barroso</h1>
                <h2>Desenvolvedor Full Stack</h2>

                <p>Com experiência em desenvolvimento Front-end, Back-end, UI/UX e DevOps, estou a mais de 4 anos transformando e ajudando empresas a alavancarem seus sistemas através da internet.</p>

                <Button text="Baixar CV" type="gradient" @clickButton="downloadCV"></Button>
                <Button text="Entrar em contato" type="gray" @clickButton="openModalContact"></Button>
            </article>

            <figure id="infos-personal-figure">
                <img src="@/assets/img/vitor_barroso_dev_icon.svg" alt="Vitor Barroso">
            </figure>
        </section>

        <section id="about-me" class="container">
            <figure id="infos-about-me-figure">
                <img src="@/assets/img/vitor_barroso_profile.svg" alt="Vitor Barroso">
            </figure>

            <article id="infos-about-me">
                <h3>Quem sou</h3>
                <h2>Vitor Barroso</h2>
                <h4>Desenvolvedor Full Stack Pleno</h4>

                <p>
                    Apaixonado por tecnologias desde criança, comecei a programar com apenas 11 anos, na linguagem Java. Apesar de ser fissurado por tecnologias, ingressei no mercado de trabalho na área de T.I. Em 2020.
                </p>

                <p>
                    Nesse meio tempo, adquiri vasta experiência com desenvolvimento Front-end, Back-end, técnicas de UI e UX, Design e DevOps.
                </p>

                <p>
                    Atualmente, trabalhando com as tecnologias Vue.js, .Net 6 e Laravel. Possuo a versatilidade de trabalhar com qualquer tecnologia que seja necessária para a execução do trabalho.
                </p>
            </article>
        </section>

        <section id="work-experiences">
            <section class="container">
                <h1>Experiências</h1>
                <TabsInfos :data="workingExperiencesList" />
            </section>
        </section>

        <section id="projects" class="container">
            <h1>Projetos</h1>
            
            <section id="projects-list">
                <ProjectCard v-for="(project, index) in projects" :data="project" @openModalError="openModalError('INDISPONÍVEL!', 'Infelizmente esse conteúdo não está mais disponível. Entre em contato com o Vitor para mais explicações!')" />
            </section>
        </section>

        <section id="languages-experiences">
            <section class="container">
                <h1>Conhecimentos</h1>
                
                <HoverExperiences :data="languagesExperiences" />
            </section>
        </section>

        <Footer />
    </div>
</template>

<style src="@/assets/css/home-style.css" scoped></style>