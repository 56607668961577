<template>
    <footer>
        <p>Copyright © 2024 - Todos os direitos reservados à <span>Vitor Barroso</span>. </p>
    </footer>
</template>

<style scoped>
    footer {
        font-size: 16px;
        color: white;
        text-align: center;
        padding: 40px 0px;
    }

    footer span {
        font-weight: 600;
        background: var(--primary-text-gradient);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }
</style>